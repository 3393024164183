import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';

import { useHistory } from 'react-router';
import {
	CancelNewOrgFlow,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { INewOrgInitialState } from 'reducers/CreateOrg';

function OrgCreateWizard({ content }: IOrgCreateWizard) {
	const history = useHistory();
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);

	const backeventHandler = () => {
		if(newOrgStore.Step >0)
		{
			dispatch(SetNewOrgFlowStep(newOrgStore.Step - 1));
		}
		else{
			history.push('/chooseorg');
		}
	};

	return (
		<div> 
			<div className='relative'>
				<div className='pt-5 mx-auto container'>
					<div className='px-4 lg:px-0 lg:mt-6 mt-4 flex flex-col lg:flex-row gap-6 lg:gap-0'>
						<div className='flex gap-4 lg:gap-6'>
							<Button
								title='Back'
								onClick={backeventHandler}
								variant='outlined'
								color='inherit'
								startIcon={
									<Icon
										icon={IconTypes.ArrowBackIos}
										className='text-sm text-gray-dark'
									/>
								}
							/>
							<Button
								title='Cancel'
								onClick={() => {
									dispatch(CancelNewOrgFlow());
									history.push('/chooseorg');
								}}
								variant='outlined'
								color='inherit'
							/>
						</div>
					</div>

					<div className='mt-6 lg:mt-28'>{content}</div>
				</div>
			</div>
		</div>
	);
}

export interface IOrgCreateWizard {
	content: any;
}

export default OrgCreateWizard;
