import Slide from '@mui/material/Slide';
import { checkIfOrganizationExists } from 'firebaseApis/authentication';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';
import OrgNameImg from 'assets/img/org-name.svg';
import OrgNametyoBarImg from 'assets/img/org-name-typo-arrow.svg';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import {
	SetNewOrgData,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { INewOrgInitialState } from 'reducers/CreateOrg';

function OrgName() {
	const [orgNameAvailable, setOrgNameAvailable] = useState('');
	const dispatch = useDispatch();
	const newOrgData: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);

	const continueHandler = () => {
		dispatch(SetNewOrgFlowStep(newOrgData.Step + 1));
	};

	useEffect(() => {
		checkIfOrganizationExists(newOrgData.Org_Data.Org_Name).then((res) => {
			setOrgNameAvailable(res);
		});
	}, [newOrgData.Org_Data.Org_Name]);

	useEffect(() => {
		dispatch(increaseProgressVal(50));
	}, []);

	return (
		<div className='w-full mx-auto'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='flex flex-col items-center justify-center pb-5 org-name-page-ct'>
					<div className='flex flex-col lg:flex-row items-center'>
						<div
							className='flex flex-col w-full col-span-2 bg-white p-4 rounded-xl industryPage-left-ct bg-no-repeat bg-right bg-contain bg-origin-content'
							style={{
								backgroundImage: `url(${OrgNameImg})`,
							}}
						>
							<div className='h-full lg:pl-8 flex flex-col justify-center w-full lg:w-11/12'>
								<h1 className='text-xl xl:text-2xl font-semibold uppercase mb-4 lg:mb-8 text-info-600 whitespace-wrap lg:whitespace-nowrap'>
									{newOrgData.Org_Flow_Type === 'PARENT'
										? 'What’s your business/Organization Name?'
										: 'What’s your Sub-Organization Name?'}
								</h1>
								<div className='w-full lg:w-3/5'>
									<InputTextField
										name='orgName'
										fieldId='orgName'
										onChange={(e) => {
											dispatch(
												SetNewOrgData({
													...newOrgData.Org_Data,
													Org_Name: e.target.value,
												})
											);
										}}
										value={newOrgData.Org_Data.Org_Name}
									/>

									{orgNameAvailable === 'no' && newOrgData.Org_Data.Org_Name !== '' && (
										<p className='text-attention-500'>Organization name already exists</p>
									)}
								</div>
								<div className='relative org-name-typo-bar-ct w-3/5'>
									<img src={OrgNametyoBarImg} alt='org-name-typo-bar' />
									<span className='absolute text-primary-500 text-xs typo-label'>
										no typos allowed
									</span>
								</div>
							</div>
						</div>
						<div className='flex flex-col p-2 lg:p-14'>
							<p className='lg:text-2xl text-dark-600 text-lg xl:text-3xl pb-4 lg:pb-8'>
								A unique identifier for your account.
							</p>
							<p className='lg:text-2xl text-dark-600 font-bold text-lg xl:text-3xl pb-2'>
								You will not be able to change this.
							</p>
							<p className='text-dark-400 text-lg xl:text-xl font-light'>
								(One of the few things you won’t be able to dynamically change)
							</p>
						</div>
					</div>
					<div className='bottom-section w-full text-center mt-8 lg:mt-20'>
						<Button
							disabled={
								newOrgData.Org_Data.Org_Name === '' ||
								orgNameAvailable === '' ||
								orgNameAvailable === 'no'
							}
							title='continue'
							onClick={() => continueHandler()}
						/>
					</div>
				</div>
			</Slide>
		</div>
	);
}

export default OrgName;
