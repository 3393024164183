import {
	getClientDB,
	getOrganisationIdFirebase,
	getUser,
	create,
	siteConfigConstants,
	getUserName,
} from 'api/AxiosManager';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import moment from 'moment';
import { IField } from 'views/Dynamic/Common/interfaces';
import { IFilter } from 'views/Marketing/Segments/Common/interfaces';

const getAppliedFiltersInRequiredFormat = (filters: IFilter[]) => {
	let appliedFiltersInRequiredFormat = [];
	filters.forEach((item: IFilter, index) => {
		const { fieldName, groupName, dataType } = item.selectedAttributeForFilter;
		const condition = item.selectedCondition.value;

		// ex : Emails , Phones
		let groupedFieldName = item.subCollectionName === '' ? groupName : '';
		if (dataType.toLowerCase() === 'array') {
			groupedFieldName = fieldName;
		}

		let Operator = condition;
		if (dataType.toLowerCase() === 'array') {
			Operator = 'IN';
		}

		if (item.dataSetName !== 'campaign_interaction_history') {
			appliedFiltersInRequiredFormat.push({
				groupedFieldName,

				// ex : Firstname , Lastname (Which are in root level)
				fieldName,

				// ex : Email_Id , Phone_Number (Which are in grouped fields)
				groupedFieldKey: groupName !== '' ? fieldName : '',

				Operator,

				logical_seperator_with_prev:
					index === 0 ? 'AND' : item.previousFilterRelation,
				fieldValue:
					dataType?.toUpperCase() === 'INTEGER'
						? Number(item.selectedConditionFieldValue)
						: item.selectedConditionFieldValue,
				data_type: dataType?.toUpperCase().replace('BOOL', 'BOOLEAN'),
				subCollectionName: item.subCollectionName,
				dataSetName: item.dataSetName,
			});
		} else {
			const commonProperties = {
				groupedFieldName: '',
				groupedFieldKey: '',
				logical_seperator_with_prev: 'AND',
				data_type: 'STRING',
				subCollectionName: item.subCollectionName,
				dataSetName: item.dataSetName,
				Operator: '=',
			};
			let interactionRelateFilters = [];
			if (item.additionalData?.campaignId) {
				interactionRelateFilters = [
					{
						fieldName: 'campaign_id',
						fieldValue: item.additionalData?.campaignId,
						...commonProperties,
					},
					{
						fieldName: 'event_time',
						fieldValue: item.selectedConditionFieldValue,
						...commonProperties,
						data_type: 'DATE',
						Operator: condition,
					},
				];
			} else {
				interactionRelateFilters = [
					{
						fieldName: 'channel',
						fieldValue: item.additionalData.API_Call.channel,
						...commonProperties,
					},
					{
						fieldName: 'event_type',
						fieldValue: item.additionalData.API_Call.event_type,
						...commonProperties,
					},
					{
						fieldName: 'event_time',
						fieldValue: item.selectedConditionFieldValue,
						...commonProperties,
						data_type: 'DATE',
						Operator: condition,
					},
				];
			}

			appliedFiltersInRequiredFormat = [
				...appliedFiltersInRequiredFormat,
				...interactionRelateFilters,
			];
		}
	});

	return appliedFiltersInRequiredFormat;
};

export const GetSelectedSegmentModalAttributes = async (segmentModal) => {
	try {
		const attributes: any = [];
		if (segmentModal !== 'Campaign Interactions') {
			const docResult: any = await getDoc(
				doc(getClientDB(), getOrganisationIdFirebase(), `${segmentModal}_UI`)
			);
			const jsonData = docResult.data();
			jsonData.Tabs.forEach((tab: any) =>
				tab.Sections.forEach((section: any) =>
					section.Fields.forEach((field: IField) => {
						if (field.FieldType === 'group') {
							field.GroupedFields.forEach((nestedField: any) => {
								let options: any = nestedField.Options;
								if (nestedField.StoryBookComponent === 'SwitchField') {
									options = [
										{
											OptionValue: true,
											OptionLabel: 'True',
										},
										{
											OptionValue: false,
											OptionLabel: 'False',
										},
									];
								}
								attributes.push({
									fieldName: nestedField.DbFieldMapping,
									dataType: nestedField.FieldValidation.DataType,
									options,
									groupName: field.GroupName,
									subCollectionName: jsonData?.DynamicModuleAssociationFields?.includes(
										field.GroupName
									)
										? field.GroupName
										: '',
									additionalData: null,
									component: nestedField.StoryBookComponent,
								});
							});
						} else {
							let options: any = field.Options;
							if (field.StoryBookComponent === 'SwitchField') {
								options = [
									{
										OptionValue: true,
										OptionLabel: 'True',
									},
									{
										OptionValue: false,
										OptionLabel: 'False',
									},
								];
							}

							if (field.StoryBookComponent === 'CheckboxGroup') {
								options = [];
								field.Options.forEach((option: any) => {
									options.push({
										OptionValue: option,
										OptionLabel: option,
									});
								});
							}
							attributes.push({
								fieldName: field.DbFieldMapping,
								dataType: field.FieldValidation.DataType,
								options,
								subCollectionName: '',
								groupName: '',
								additionalData: null,
								component: field.StoryBookComponent,
								OptionLabel: field?.OptionLabel || '',
								OptionValue: field?.OptionValue || '',
							});
						}
					})
				)
			);
		} else {
			/* const docResult: any = await getDoc(
				doc(
					getClientDB(),
					getOrganisationIdFirebase(),
					`Lookups/Segments/Interactions`
				)
			); */
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Lookups/Segments`
				)
			);
			const docResult: any = await getDocs(qry);
			const resultArray = docResult.docs.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));
			resultArray[0].Segment_Data.forEach((field) => {
				attributes.push({
					fieldName: field.Name,
					dataType: 'INTERACTION',
					additionalData: field,
					attributes: field.Data,
					options: [],
					groupName: '',
				});
			});
		}

		return attributes;
	} catch (error) {
		return error;
	}
};

export const GetSegmentResultsCountWithFilters = async (
	appliedFiltersList: IFilter[],
	segmentType
) => {
	try {
		const queryObj = getAppliedFiltersInRequiredFormat(appliedFiltersList);

		const payloadObj = {
			defaultDataSetName: segmentType,
			query: queryObj,
			organization_id: getOrganisationIdFirebase(),
		};
		const instance = create();
		const response = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/getDynamicDataSetRelation`,
			payloadObj
		);
		return response?.data?.length ?? 0;
	} catch (error) {
		return 0;
	}
};

// temp
export const GetContactsDataWithFilters = async (
	appliedFiltersList: IFilter[]
) => {
	try {
		const queryObj = getAppliedFiltersInRequiredFormat(appliedFiltersList);

		const payloadObj = {
			query: queryObj,
			organization_id: getOrganisationIdFirebase(),
			start_event_date: '2020-01-01',
		};
		const instance = create();
		const response = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/importexportcontacts/simpleContactSearch?count_only=true`,
			payloadObj
		);
		return response?.data[0]?.no_of_records ?? 0;
	} catch (error) {
		return error;
	}
};

export const GetSegmentResultsDataWithFilters = async ({
	appliedFilters,
	pageNum,
	pageSize,
	segmentType,
}) => {
	try {
		const queryObj = getAppliedFiltersInRequiredFormat(appliedFilters);

		const payloadObj = {
			defaultDataSetName: segmentType,
			query: queryObj,
			organization_id: getOrganisationIdFirebase(),
			start_event_date: '2020-01-01',
			numPerPage: pageSize,
			page: pageNum,
		};
		const instance = create();
		const response = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/getDynamicDataSetRelationPagenation?called_from=UI`,
			payloadObj
		);
		const data = response?.data?.results.map((item: any) => ({
			id: item.document_id,
			...item.document,
		}));
		return {
			data,
			pagination: response?.data?.pagination,
		};
	} catch (error) {
		return error;
	}
};

export const checkIfSegmentNameExists = async (name: any) => {
	try {
		const orgSnapshots = await getDocs(
			query(
				collection(
					getClientDB(),
					`${getOrganisationIdFirebase()}/Segments/Segments`
				),
				where('Segment_Name', '==', name)
			)
		);
		const resultArray = orgSnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		let result = {};
		if (orgSnapshots.size === 0) {
			result = {
				success: false,
			};
		} else {
			result = {
				success: true,
				segmentId: resultArray[0].id,
			};
		}
		return result;
	} catch (error) {
		return error;
	}
};

export const SaveSegment = async (payload: any) => {
	try {
		const queryObj = getAppliedFiltersInRequiredFormat(payload.Filters);
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Segments/Segments`
			),
			{
				...payload,
				Filters_Server: queryObj,
				Filters_UI: payload.Filters,
				Created_At: moment().toString(),
				Created_By: `${getUser().fName} ${getUser().lName}`,
				Updated_By: getUserName(),
				Updated_On: moment().toString(),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetAllSegments = async ({ segmentType }) => {
	try {
		let qry: any;

		if (segmentType !== 'all') {
			qry = query(
				collection(
					getClientDB(),
					`${getOrganisationIdFirebase()}/Segments/Segments`
				),
				where('Segment_Type', '==', segmentType)
			);
		} else {
			qry = query(
				collection(
					getClientDB(),
					`${getOrganisationIdFirebase()}/Segments/Segments`
				)
			);
		}

		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetSegmentById = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Segments/Segments`, id)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const UpdateSegment = async (payload: any, id) => {
	try {
		const queryObj = getAppliedFiltersInRequiredFormat(payload.Filters);
		const result = await setDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Segments/Segments`, id),

			{
				...payload,
				Filters_Server: queryObj,
				Filters_UI: payload.Filters,
				Created_At: moment().toString(),
				Created_By: `${getUser().fName} ${getUser().lName}`,
				Updated_At: moment().toString(),
				Updated_By: getUserName(),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const DeleteSegment = async (id: any) => {
	try {
		const result = await deleteDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Segments/Segments`, id)
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetDynamicModulesAssociatedWithDynamicModule = async (module) => {
	try {
		const instance = create();
		const response: any = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/getAllSubCollections?dataset_name=${module}&organization_id=${getOrganisationIdFirebase()}`
		);
		return response?.data;
	} catch (error) {
		return error;
	}
};

export const GetAllSegmentTypes = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'DynamicModules')
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const InsertDataInBigQuery = async (data) => {
	const instance = create();
	try {
		const result = await instance.post(
			`/dynamicdataset/insertSingleRow?organization_id=${getOrganisationIdFirebase()}&docid=${
				data.documentId
			}&dataset_name=${data.moduleName}`
		);
		return result;
	} catch (e) {
		return e;
	}
};

export const UpdateDataInBigQuery = async (data) => {
	const instance = create();
	try {
		const result = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/updateRow?organization_id=${getOrganisationIdFirebase()}&docid=${
				data.documentId
			}&dataset_name=${data.moduleName}`
		);
		return result;
	} catch (e) {
		return e;
	}
};

export const DeleteDataInBigQuery = async (data) => {
	const instance = create();
	try {
		const result = await instance.delete(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/deleteRow?organization_id=${getOrganisationIdFirebase()}&docid=${
				data.documentId
			}&dataset_name=${data.moduleName}`
		);
		return result;
	} catch (e) {
		return e;
	}
};
