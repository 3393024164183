import { getDateFromTime } from 'helpers/Formats';
import momentTimezone from 'moment-timezone';
import {
	Day,
	Frequency,
	Interval,
	NewCampaignDispatchTypes,
	OccasionOn,
	RESET_CAMPAIGN_POST,
	SET_CAMPAIGN_COLORS,
	SET_CAMPAIGN_SUCCESS,
	SET_IN_CAMPAIGN,
	SET_ORGANIZATION_USERS,
	SET_SENDER_PROFILES,
	UPDATE_ACCOUNTS,
	UPDATE_AUDEIENCE,
	UPDATE_BCC_EMAIL,
	UPDATE_CAMPAIGN_DETAILS,
	UPDATE_CAMPAIGN_RUN_TYPE,
	UPDATE_CC_EMAIL,
	UPDATE_CRON_SCHEDULE_VALUE,
	UPDATE_DAY,
	UPDATE_DESCRIPTION,
	UPDATE_EMAIL_CONTENT,
	UPDATE_EMAIL_SUBJECT,
	UPDATE_END_DATE,
	UPDATE_END_TIME,
	UPDATE_FREQUENCY,
	UPDATE_INTERVAL,
	UPDATE_IS_PROCESSED,
	UPDATE_ITEM,
	UPDATE_NAME,
	UPDATE_NOTIFY_OTHERS,
	UPDATE_OCCASION,
	UPDATE_OCCASION_ADDITIONALDATA,
	UPDATE_OCCASION_DAY,
	UPDATE_OCCASION_ON,
	UPDATE_OCCASION_TIME,
	UPDATE_OCCASION_TIMEZONE,
	UPDATE_PLATFORMS,
	UPDATE_SCHEDULE_DETAILS,
	Update_SMS_CREATIVE_ID_IN_CAMPAIGN,
	UPDATE_START_DATE,
	UPDATE_START_TIME,
	UPDATE_TIMEZONE,
	UPDATE_TRIGGER_DETAILS,
	UPDATE_TYPE,
	UPDATE_VIEW,
	Update_VOICE_CREATIVE_ID_IN_CAMPAIGN,
} from './NewCampaignActionTypes';

interface INewCampaignFlow {
	currentView: number;
	campaignType: string;
	name: string;
	description: string;
	item: any;
	audience: number;
	platforms: string[];
	accounts: string[];
	interval: Interval;
	frequency: Frequency;
	day: Day[];
	startDate: any;
	startTime: any;
	endDate: any;
	endTime: any;
	schedule_timezone: any;
	occasion: string;
	occasion_AdditionalData: any;
	occasion_on: OccasionOn;
	occasion_day: number;
	occasion_time: string;
	occasion_timezone: any;
	campaign_success: boolean;
	cronScheduleValue: string;
	ccEmails: string;
	bccEmails: string;
	plainContent: string;
	subject: string;
	campaignColors: any;
	senderProfiles: any;
	campaignDetails: any;
	organizationUsers: any;
	isProcessed: boolean;
	notifyOthers: any;
	scheduleDetails: {
		startDate: any;
		startTime: any;
		occasion_timezone: any;
		campaignPeriod: string;
		customPeriod: string;
		recurringScheduleStartType: string;
		recurringStartsOn: any;
		recurringScheduleEndType: string;
		recurringEndsOn: any;
		occurences: number;
		runTimeInSeconds: number;
		cancelAfterSeconds: string;
		cronData: any;
	};
	triggerDetails: {
		selectedTriggers: [];
	};
	scheduleType: string;
	inCampaign: boolean;
	runType: string;
}

const initialState: INewCampaignFlow = {
	currentView: 0,
	campaignType: '',
	name: '',
	description: '',
	subject: '',
	plainContent: '',
	runType: '',
	item: {
		subjectHaveDynamicVariable: false,
		plain_contentHaveDynamicVariable: false,
	},
	audience: null,
	platforms: [],
	accounts: [],
	frequency: null,
	interval: null,
	day: [],
	startDate: new Date(),
	startTime: '',
	endDate: new Date(),
	endTime: '',
	schedule_timezone: '',
	occasion: '',
	occasion_on: null,
	occasion_AdditionalData: {},
	occasion_day: undefined,
	occasion_time: '',
	occasion_timezone: '',
	campaign_success: false,
	isProcessed: false,
	cronScheduleValue: '* * * * *',
	ccEmails: '',
	bccEmails: '',
	campaignColors: [],
	organizationUsers: [],
	senderProfiles: [],
	notifyOthers: [],
	campaignDetails: {
		configurationFor: '',
		campaignName: '',
		description: '',
		campaignOwner: '',
		campaignApprover: '',
		createdBy: '',
		channels: { email: false, sms: false, voice: false },
		segment: { Segment_Type: 'Person' },
	},
	scheduleDetails: {
		startDate: momentTimezone.tz('America/Yakutat').format('MM/DD/YYYY'),
		startTime: getDateFromTime(
			momentTimezone.tz('America/Yakutat').format('HH:mm')
		),
		occasion_timezone: '',
		campaignPeriod: 'notRepeat',
		recurringScheduleStartType: '',
		recurringStartsOn: '',
		recurringScheduleEndType: 'never',
		recurringEndsOn: '',
		occurences: 1,
		runTimeInSeconds: 0,
		cancelAfterSeconds: '',
		customPeriod: 'day',
		cronData: {
			timePeriod: 'Day',
			everyMonth: '',
			everyDayOfMonth: '',
			everyDayOfWeek: '',
			everyHour: '',
			everyMinute: '',
		},
	},
	triggerDetails: {
		selectedTriggers: [],
	},
	scheduleType: '',
	inCampaign: false,
};

const userReducer = (
	state: any = initialState,
	action: NewCampaignDispatchTypes
): INewCampaignFlow => {
	switch (action.type) {
		case UPDATE_TYPE:
			return { ...state, campaignType: action.campaignType };
		case UPDATE_VIEW:
			return { ...state, currentView: action.currentView };
		case UPDATE_NAME:
			return { ...state, name: action.name };
		case UPDATE_DESCRIPTION:
			return { ...state, description: action.description };
		case UPDATE_ITEM:
			return { ...state, item: action.item };
		case UPDATE_AUDEIENCE:
			return { ...state, audience: action.audience };
		case UPDATE_PLATFORMS:
			return { ...state, platforms: action.platforms };
		case UPDATE_ACCOUNTS:
			return { ...state, accounts: action.accounts };
		case UPDATE_FREQUENCY:
			return { ...state, frequency: action.frequency };
		case UPDATE_INTERVAL:
			return { ...state, interval: action.interval };
		case UPDATE_DAY:
			return { ...state, day: action.day };
		case UPDATE_START_DATE:
			return { ...state, startDate: action.startDate };
		case UPDATE_START_TIME:
			return { ...state, startTime: action.startTime };
		case UPDATE_END_DATE:
			return { ...state, endDate: action.endDate };
		case UPDATE_END_TIME:
			return { ...state, endTime: action.endTime };
		case UPDATE_TIMEZONE:
			return { ...state, schedule_timezone: action.schedule_timezone };
		case UPDATE_OCCASION:
			return { ...state, occasion: action.occasion };
		case UPDATE_OCCASION_ON:
			return { ...state, occasion_on: action.occasion_on };
		case UPDATE_OCCASION_ADDITIONALDATA:
			return { ...state, occasion_AdditionalData: action.occasion_AdditionalData };
		case UPDATE_OCCASION_DAY:
			return { ...state, occasion_day: action.occasion_day };
		case UPDATE_OCCASION_TIME:
			return { ...state, occasion_time: action.occasion_time };
		case UPDATE_CRON_SCHEDULE_VALUE:
			return { ...state, cronScheduleValue: action.cronScheduleValue };
		case UPDATE_OCCASION_TIMEZONE:
			return { ...state, occasion_timezone: action.occasion_timezone };
		case UPDATE_IS_PROCESSED:
			return { ...state, occasion_timezone: action.isProcessed };
		case SET_CAMPAIGN_SUCCESS:
			return { ...state, campaign_success: action.campaign_success };
		case UPDATE_CC_EMAIL:
			return { ...state, ccEmails: action.ccEmails || [] };
		case UPDATE_BCC_EMAIL:
			return { ...state, bccEmails: action.bccEmails || [] };
		case UPDATE_CAMPAIGN_RUN_TYPE:
			return { ...state, runType: action.runType || '' };
		case UPDATE_CAMPAIGN_DETAILS:
			return { ...state, campaignDetails: action.campaignDetails };
		case SET_IN_CAMPAIGN:
			return { ...state, inCampaign: action.inCampaign };
		case UPDATE_SCHEDULE_DETAILS:
			return {
				...state,
				scheduleDetails: action.scheduleDetails || initialState.scheduleDetails,
			};
		case UPDATE_TRIGGER_DETAILS:
			return { ...state, triggerDetails: action.triggerDetails };
		case SET_SENDER_PROFILES:
			return { ...state, senderProfiles: action.senderProfiles };
		case SET_CAMPAIGN_COLORS:
			return { ...state, campaignColors: action.campaignColors };
		case SET_ORGANIZATION_USERS:
			return { ...state, organizationUsers: action.organizationUsers };
		case Update_SMS_CREATIVE_ID_IN_CAMPAIGN:
			return { ...state, smsCreativeId: action.id };
		case Update_VOICE_CREATIVE_ID_IN_CAMPAIGN:
			return { ...state, voiceCreativeId: action.id };
		case UPDATE_EMAIL_SUBJECT:
			return { ...state, subject: action.subject || '' };
		case UPDATE_EMAIL_CONTENT:
			return { ...state, plainContent: action.plainContent || '' };
		case UPDATE_NOTIFY_OTHERS:
			return { ...state, notifyOthers: action.notifyOthers };
		case RESET_CAMPAIGN_POST:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default userReducer;
