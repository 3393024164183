import Slide from '@mui/material/Slide';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { toast } from 'react-toastify';
import { Spinner } from 'components/Spinner';
import { GetAllCountries, GetTimeZones } from 'firebaseApis/authentication';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import Button from 'storybook-mui/build/components/Button';
import GlobeImage from 'assets/img/globe-earth.svg';
import TimeZoneFilter from 'components/TimeZoneFilter';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import InputTextField from 'storybook-mui/build/components/TextField';
import { isJsonString } from 'helpers/utils';
import {
	SetNewOrgData,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import {
	getGeoLocation,
	getTimeZoneOfGeoLocation,
} from '../../../api/NewOrganization';

function CountrySelection() {
	const [countries, setCountries] = useState<[]>([]);
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const userLiveLocationInitialState = {
		locality: '',
		city: '',
		state: '',
		pincode: '',
		country: '',
	};
	const [errors, setErrors] = useState('');
	const [timeZones, setTimeZones] = useState<[]>([]);
	const [selectedCountry, setSelectedCountry] = useState({
		label: 'United States',
		value: 'US',
	});
	const [userLiveLocationInfo, setUserLiveLocationInfo] = useState(
		userLiveLocationInitialState
	);
	const [stateOptions, setStateOptions] = useState([]);
	const [stateVal, setStateVal] = useState<any | null>({ label: '', value: '' });
	const [timezoneVal, setTimezoneVal] = useState<any | null>({
		label: '',
		value: '',
	});
	const [cityVal, setCityVal] = useState('');
	const [userLocality, setLocality] = useState('');
	const [userLattd, setUserLattd] = useState(null);
	const [userLongtd, setUserlongtd] = useState(null);
	const [isGeoLocationApiCalled, setGeoLocationApiCalled] = useState(false);
	const [isTZApiCalled, setTZApiCalled] = useState(false);
	const toastIdtarget = React.useRef(null);
	const getTimeZones = async () => {
		const result = await GetTimeZones();
		const tempTimeZones: any = [];
		result
			.filter(
				(country) =>
					country.Country ===
					(selectedCountry.label === '' ? 'United States' : selectedCountry.label)
			)
			.forEach((obj: any) => {
				tempTimeZones.push({
					value: `${obj.TImezonewithUTC}`,
					label: `${obj.TImezonewithUTC}`,
					groupBy:
						selectedCountry.label === ''
							? 'United States Time Zones'
							: `${selectedCountry.label} Time Zones`,
				});
			});
		setTimeZones(tempTimeZones);
	};
	const getCountries = async () => {
		try {
			dispatch(showLoading());
			const result = await GetAllCountries();
			const dataSource = result.map((item: any) => ({
				label: item.country_name,
				value: item.country_id,
				id: item.country_id,
				countryCode: item.phone_code,
				currency: item.currency_id,
				timeformat: item.time_format_id,
				dateformat: item.date_format_id,
				language: item.primary_language,
				states: item.states,
				renderLabel: (
					<div className='flex'>
						<img src={item.flag_svg_url} className='h-6 w-6 mx-2' />
						{item.country_name}
					</div>
				),
			}));
			setCountries(dataSource);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const getStates = () => {
		const states = [];
		if (selectedCountry && selectedCountry.value) {
			const country: any = countries.find(
				(c: any) => c.value === selectedCountry.value
			);
			country?.states?.forEach((state) => {
				states.push({
					value: state.name,
					label: state.name,
				});
			});
		}
		setStateOptions(states);
	};

	const onCountryChange = (country: any) => {
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				Country: JSON.stringify(country),
				User_City: '',
				User_Locality: '',
				User_State: '',
				Time_Zone: '',
			})
		);
		setSelectedCountry(country);
		setStateVal({ label: '', value: '' });
		setCityVal('');
		setLocality('');
		setTimezoneVal({ label: '', value: '' });
		getStates();
	};
	const onNext = () => {
		if (!timezoneVal || timezoneVal?.value === '') {
			setErrors('Please select the timezone');
			return;
		}
		let selectCountry = selectedCountry;
		if (selectedCountry && Object.keys(selectedCountry).length === 2) {
			const reqCountryObj = countries.find(
				(country: any) => country.label === selectedCountry.label
			);
			selectCountry = reqCountryObj;
		}

		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				Country: JSON.stringify(selectCountry),
				Time_Zone: JSON.stringify(timezoneVal),
				User_City: cityVal,
				User_Locality: userLocality,
				User_State: JSON.stringify({
					label: stateVal.label,
					value: stateVal.label,
				}),
			})
		);
		dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1));
	};

	const getUserLocationInfoFrmResponse = (resp) => {
		const addressInfoList = resp.results[0].address_components;
		const addressmapObj = {
			administrative_area_level_2: 'city',
			administrative_area_level_1: 'state',
			postal_code: 'pincode',
			country: 'country',
			locality: 'locality',
		};
		const userGeoLocInfoObj: any = {};
		addressInfoList.forEach((address) => {
			userGeoLocInfoObj[addressmapObj[address.types[0]]] = address?.long_name
				? address?.long_name
				: null;
		});
		const { locality, city, state, pincode, country } = userGeoLocInfoObj;
		setUserLiveLocationInfo({
			locality,
			city,
			state,
			pincode,
			country,
		});
		return userGeoLocInfoObj;
	};

	const setTimezoneBasedOnLocation = async (lat, long) => {
		try {
			const timestamp = Math.round(new Date().getTime() / 1000);
			const isTargetCountry = timeZones.some((item: any) =>
				item.groupBy.includes(userLiveLocationInfo.country)
			);
			if (isTargetCountry) {
				setTZApiCalled(true);
				const timeZoneResponse: any = await getTimeZoneOfGeoLocation(
					lat,
					long,
					timestamp
				);
				if (timeZoneResponse?.status === 'OK') {
					const reqTimezone: any = timeZones.filter((tz: any) =>
						tz.value.includes(timeZoneResponse.timeZoneId)
					);
					if (reqTimezone && reqTimezone.length > 0) {
						setTimezoneVal(reqTimezone[0]);
						dispatch(
							SetNewOrgData({
								...newOrgStore.Org_Data,
								Time_Zone: JSON.stringify(reqTimezone[0]),
							})
						);
					}
				}
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const reverseGeocodingWithGoogle = async (latitude, longitude) => {
		try {
			dispatch(showLoading());
			const geolocationResponse: any = await getGeoLocation(latitude, longitude);
			if (geolocationResponse?.status === 'OK') {
				const geoInfo = getUserLocationInfoFrmResponse(geolocationResponse);
				if (Object.keys(geoInfo.length > 0)) {
					const liveCountry = geoInfo.country;
					const isCurrentCountry =
						liveCountry &&
						countries.filter((country: any) => country.label === liveCountry);
					if (isCurrentCountry && isCurrentCountry.length > 0) {
						setSelectedCountry(isCurrentCountry[0]);
						setStateVal({ label: geoInfo.state, value: geoInfo.state });
						setCityVal(geoInfo.locality);
						setLocality(geoInfo.locality);
						setUserLiveLocationInfo(geoInfo);
						dispatch(
							SetNewOrgData({
								...newOrgStore.Org_Data,
								Country: JSON.stringify(isCurrentCountry[0]),
								User_City: geoInfo.locality,
								User_Locality: geoInfo.locality,
								User_State: JSON.stringify({
									label: geoInfo.state,
									value: geoInfo.state,
								}),
							})
						);
					} else {
						setUserLiveLocationInfo(userLiveLocationInitialState);
						dispatch(hideLoading());
					}
				}
			} else {
				Toast({
					title: `Unable to locate your geo location, Please select manually`,
					type: ToastTypes.ERROR,
				});
				dispatch(hideLoading());
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const getLiveGeoLocation = () => {
		if (!navigator.geolocation) {
			Toast({
				title: 'Geolocation is not supported by your browser',
				type: ToastTypes.ERROR,
			});
			return;
		}
		function success(position) {
			const { latitude } = position.coords;
			const { longitude } = position.coords;
			if (countries.length > 0) {
				setUserLattd(latitude);
				setUserlongtd(longitude);
				setGeoLocationApiCalled(true);
				reverseGeocodingWithGoogle(latitude, longitude);
			}
		}
		function error() {
			if (!toast.isActive(toastIdtarget.current)) {
				dispatch(hideLoading());
				Toast({
					title: 'Unable to locate your geo location, Please select manually',
					type: ToastTypes.INFO,
					toastId: 'nolocation-toast',
				});
			}
		}
		navigator.geolocation.getCurrentPosition(success, error);
	};

	const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCityVal(event.target.value);
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				User_City: event.target.value ? event.target.value : '',
			})
		);
	};

	const handleChangeState = (state: any) => {
		if (state) {
			setStateVal(state);
		} else {
			setStateVal({ label: '', value: '' });
		}
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				User_State: JSON.stringify({ label: state.state, value: state.state }),
			})
		);
	};

	const autoPopulateFieldValues = () => {
		if (newOrgStore.Org_Data.Country) {
			let country = { label: 'United States', value: 'US' };
			if (isJsonString(newOrgStore.Org_Data.Country)) {
				country = JSON.parse(newOrgStore.Org_Data.Country);
			}
			dispatch(hideLoading());
			setSelectedCountry(country);
		}
		if (newOrgStore.Org_Data.User_City) {
			setCityVal(
				newOrgStore.Org_Data.User_City ? newOrgStore.Org_Data.User_City : null
			);
		}
		if (newOrgStore.Org_Data.User_State) {
			let state = { label: '', value: '' };
			if (isJsonString(newOrgStore.Org_Data.User_State)) {
				state = JSON.parse(newOrgStore.Org_Data.User_State);
			}
			setStateVal({ label: state.label, value: state.value });
		}
		if (newOrgStore.Org_Data.Time_Zone) {
			let timezone = { label: '', value: '' };
			if (isJsonString(newOrgStore.Org_Data.Time_Zone)) {
				timezone = JSON.parse(newOrgStore.Org_Data.Time_Zone);
			}
			setTimezoneVal(timezone);
			dispatch(hideLoading());
		}
		if (newOrgStore.Org_Data.User_Locality) {
			const localty = newOrgStore.Org_Data.User_Locality
				? newOrgStore.Org_Data.User_Locality
				: '';
			setLocality(localty);
		}
	};

	const onChangeTimeZone = (option) => {
		setErrors('');
		setTimezoneVal(option);
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				Time_Zone: JSON.stringify(option),
			})
		);
	};

	useEffect(() => {
		if (
			isGeoLocationApiCalled &&
			timeZones.length > 0 &&
			!isTZApiCalled &&
			userLiveLocationInfo.country === selectedCountry.label
		) {
			setTimezoneBasedOnLocation(userLattd, userLongtd);
		}
	}, [timeZones, userLiveLocationInfo]);

	useEffect(() => {
		getTimeZones();
		getStates();
	}, [selectedCountry]);

	useEffect(() => {
		if (
			!isGeoLocationApiCalled &&
			newOrgStore.Org_Data &&
			newOrgStore.Org_Data.Time_Zone === '' &&
			!userLiveLocationInfo.city
		) {
			getLiveGeoLocation();
		}
		getStates();
	}, [countries]);

	useEffect(() => {
		getCountries();
		getTimeZones();
		autoPopulateFieldValues();
		getStates();
		dispatch(increaseProgressVal(63));
	}, []);

	return (
		<div className='w-full mx-auto px-4'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-20 items-center justify-center gap-y-8 pb-5'>
					<div
						className='flex flex-col gap-8 w-full col-span-2 bg-white p-4 rounded-xl bg-no-repeat bg-right-top bg-auto bg-origin-content'
						style={{
							backgroundImage: `url(${GlobeImage})`,
						}}
					>
						<div className='flex flex-col gap-4 w-full lg:w-11/12 lg:pl-8 py-8'>
							<h1 className='text-xl xl:text-2xl font-semibold uppercase text-info-600 whitespace-wrap'>
								In which country is your business based?
							</h1>
							{countries.length > 0 && (
								<div className='w-full lg:w-3/5'>
									<AutocompleteField
										placeholder=''
										fieldId='Country'
										onChange={(e: any, v: any) => onCountryChange(v)}
										value={selectedCountry}
										optionsType='render'
										options={countries}
									/>
								</div>
							)}
							<h1 className='text-xl xl:text-2xl font-semibold mt-3 uppercase text-info-600 whitespace-wrap'>
								What’s your timezone?
							</h1>
							<div className='w-full lg:w-3/5'>
								<TimeZoneFilter
									value={timezoneVal}
									onChange={(event, option) => {
										onChangeTimeZone(option);
									}}
									options={timeZones}
									fieldId='timeZone'
									valid={!errors}
									errorMessage='Please Select the Time Zone'
									placeholder='Timezone'
								/>
							</div>
							<h1 className='text-xl xl:text-2xl font-semibold mt-3 uppercase text-info-600 whitespace-wrap'>
								What’s your city?
							</h1>
							<div className='w-full lg:w-3/5'>
								<InputTextField
									name='city'
									labelName='City'
									fieldId='city'
									onChange={(e) => handleChangeCity(e)}
									value={cityVal}
									size='small'
								/>
							</div>
							<h1 className='text-xl xl:text-2xl font-semibold mt-3 uppercase text-info-600 whitespace-wrap'>
								What’s your state?
							</h1>
							<div className='w-full lg:w-3/5'>
								{countries.length > 0 ? (
									<AutocompleteField
										value={stateVal}
										onChange={(event: any, option: any) => {
											handleChangeState(option);
										}}
										options={stateOptions ?? []}
										fieldId='state'
										placeholder='State'
										size='small'
									/>
								) : (
									<Spinner />
								)}
							</div>
						</div>
					</div>
					<div className='flex flex-col w-full text-center items-center'>
						<p className='text-lg text-dark-600 lg:text-xl text-left'>
							This sets the default currency, language, privacy laws and data storage
							location for your account.
						</p>
					</div>
					<div className='bottom-section w-full text-center col-start-1 lg:col-start-2'>
						<Button
							disabled={selectedCountry?.value === ''}
							title='continue'
							onClick={() => onNext()}
						/>
					</div>
				</div>
			</Slide>
		</div>
	);
}

export default CountrySelection;
