import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { setEmailBuilderAssetsFromStorage } from 'firebaseApis/authentication';
import { Spinner } from 'components/Spinner';
import { IdleTimerProvider } from 'react-idle-timer';
import { clear, getUser, siteConfigConstants } from 'api/AxiosManager';

export interface IScroll {
	children: React.ReactNode;
}

const ScrollToTop = ({ children }: IScroll) => {
	const location = useLocation();

	const user = getUser();
	const history = useHistory();
	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
		const logoutUrl = siteConfigConstants().REACT_APP_LOGOUT_REDIRECT_URL;
		clear();
		if (window.location.hostname === 'dev.leap360.com') {
			history.push('/');
		} else {
			window.location.replace(`${logoutUrl}/logout`);
		}
	};

	const getEmailAssetStorageBucket = async () => {
		const result = await setEmailBuilderAssetsFromStorage(window.location.origin);
		if (result) {
			localStorage.setItem(
				'emailBuilderAssetsBucket',
				JSON.stringify(result.Constant_Value)
			);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const emailBuilderBucket = localStorage.getItem('emailBuilderAssetsBucket');

		if (!JSON.parse(emailBuilderBucket) && user?.organisationPathFirebase) {
			getEmailAssetStorageBucket();
		}
	}, [location]);

	return (
		<>
			{children}
			{Object.keys(user ?? null).length !== 0 && user?.idleTimeOut && (
				<IdleTimerProvider timeout={1000 * user.idleTimeOut} onIdle={onIdle} />
			)}
		</>
	);
	return <Spinner />;
};

export default ScrollToTop;
