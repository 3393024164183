import React, { useEffect, useState } from 'react';
import { getUser } from 'api/AxiosManager';
import { GetOrganisationDetailsById } from 'firebaseApis/organization';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import LinearProgressBar from 'components/LinearProgressBar';
import { RootStore } from 'reducers';
import { logout } from 'firebaseApis/authentication';

import Header from './components/Header';
import Nav from './components/Nav';

export function PrivateRoute({ component: Component, ...props }: any) {
	const history = useHistory();
	const user = getUser();
	const { loading, linearLoading } = useSelector(
		(state: RootStore) => state.alertsReducer
	);
	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);
	const headerNav = useSelector(
		(state: RootStore) => state.layoutReducer
	).HeaderNav;
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

	const logoutClient = async () => {
		await logout();
		if (window.location.hostname === 'dev.leap360.com') {
			history.push('/');
		}
	};
	async function checkUserAndOrgIsValidOrNot() {
		try {
			if (Object.keys(user ?? null).length === 0) {
				logoutClient();
			}
			if (
				Object.keys(user).length &&
				user?.organisationPathFirebase === undefined
			) {
				logoutClient();
			}

			if (
				Object.keys(user).length &&
				user?.organisationPathFirebase !== undefined
			) {
				try {
					const response = await GetOrganisationDetailsById(
						user.organisationPathFirebase
					);
					if (!response) {
						logoutClient();
					}
					if (Object.keys(response).length === 0) {
						history.push('/chooseorg');
					}
				} catch (error) {
					logoutClient();
				}
			}
		} catch (e) {
			logoutClient();
		}
	}

	useEffect(() => {
		setSidebarOpen(false);
		checkUserAndOrgIsValidOrNot();
	}, [history.location]);

	return (
		<>
			{loading && <Spinner />}
			{linearLoading && <LinearProgressBar delay={1500} message='Loading' />}
			<div className='flex flex-col h-full'>
				<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<div
					className={`flex bg-gray-lightest h-full ${
						layoutStore.HeaderNav && user ? 'mt-16' : 'mt-2'
					}`}
				>
					<Nav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
					<div
						className={`block md:flex flex-col flex-1 pt-4 w-full ${
							headerNav ? 'fullscreenwithheader  overflow-auto' : ''
						}`}
					>
						<main className='flex-1 relative z-0 focus:outline-none'>
							<div className='w-full mx-auto px-4 sm:px-6 md:px-8 h-full'>
								<Component {...props} />
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
}

export default PrivateRoute;
