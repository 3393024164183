import {
	RESET_ALL_EMAIL_FILTERS,
	SET_EMAIL_ADD_CAT_DATA,
	SET_EMAIL_CATEGORY_SAVE_ACTIONS,
	SET_EMAIL_CRM_CATEGORIES,
	SET_EMAIL_DELETE_DATA,
	SET_EMAIL_EXIST_MAIN_SUB_CHECK,
	SET_EMAIL_EXTERNAL_CATEGORIES,
	SET_EMAIL_FILTER,
	SET_EMAIL_FILTER_MODAL,
	SET_EMAIL_GRID_VIEW,
	SET_EMAIL_INTERNAL_CATEGORIES,
	SET_EMAIL_INTERNAL_SELECTED_CATEGORIES,
	SET_EMAIL_LOAD_TEMPLATE_BY_TYPE,
	SET_EMAIL_MAIN_CATEGORY_SAVE_ACTIONS,
	SET_EMAIL_MODAL_IMAGE,
	SET_EMAIL_MODAL_TYPE,
	SET_EMAIL_NEW_CATEGORY,
	SET_EMAIL_OPEN_MODAL,
	SET_EMAIL_PAGE_NUM,
	SET_EMAIL_PAGE_SIZE,
	SET_EMAIL_SEARCH_TEXT,
	SET_EMAIL_SELECTED_CRM_CATEGORIES,
	SET_EMAIL_SELECTED_EXTERNAL_CATEGORIES,
	SET_EMAIL_TOTAL_PAGES,
	SET_EMAIL_TOTAL_ROWS,
	SET_EMAIL_GRID_API_DATA,
	SET_EMAIL_GRID_FIRST_DOCUMENT,
	SET_EMAIL_GRID_LAST_DOCUMENT,
} from './EmailCreativeActionTypes';

const initialState = {
	categoryFilter: {
		selectedInternalCategories: [],
		selectedExternalCategories: [],
		internalCategories: [],
		externalCategories: [],
		CRMCategories: [],
		selectedInternalMainCategories: [],
		categorySaveActions: {},
		mainCategorySaveActions: {},
		deleteData: {},
		addCatData: {},
		filterModal: {},
		openModal: false,
		modalType: '',
		newCategory: '',
		modalImage: '',
		searchText: '',
		selectedCrmCategories: [],
		existMainSubCheck: [],
		apiData: [],
		loadTemplateType: 'myTemplates',
		gridView: false,
		firstDocument: null,
		lastDocument: null,
		pageSize: 10,
		pageNum: 0,
		totalRows: 0,
		totalPages: 0,
	},
	filter: false,
};
const EmailCreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_EMAIL_INTERNAL_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					internalCategories: action.payload,
				},
			};
		case SET_EMAIL_INTERNAL_SELECTED_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					selectedInternalCategories: action.payload,
				},
			};

		case SET_EMAIL_SELECTED_CRM_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					selectedCrmCategories: action.payload,
				},
			};
		case SET_EMAIL_SELECTED_EXTERNAL_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					selectedExternalCategories: action.payload,
				},
			};
		case SET_EMAIL_LOAD_TEMPLATE_BY_TYPE:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					loadTemplateType: action.payload,
				},
			};
		case SET_EMAIL_GRID_API_DATA:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					apiData: action.payload,
				},
			};
		case SET_EMAIL_GRID_FIRST_DOCUMENT:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					firstDocument: action.payload,
				},
			};
		case SET_EMAIL_GRID_LAST_DOCUMENT:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					lastDocument: action.payload,
				},
			};
		case SET_EMAIL_EXIST_MAIN_SUB_CHECK:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					existMainSubCheck: action.payload,
				},
			};
		case SET_EMAIL_OPEN_MODAL:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					openModal: action.payload,
				},
			};
		case SET_EMAIL_MODAL_TYPE:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					modalType: action.payload,
				},
			};
		case SET_EMAIL_ADD_CAT_DATA:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					addCatData: action.payload,
				},
			};
		case SET_EMAIL_FILTER_MODAL:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					filterModal: action.payload,
				},
			};
		case SET_EMAIL_PAGE_NUM:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					pageNum: action.payload,
				},
			};
		case SET_EMAIL_TOTAL_PAGES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					totalPages: action.payload,
				},
			};
		case SET_EMAIL_TOTAL_ROWS:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					totalRows: action.payload,
				},
			};
		case SET_EMAIL_PAGE_SIZE:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					pageSize: action.payload,
				},
			};
		case SET_EMAIL_MODAL_IMAGE:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					modalImage: action.payload,
				},
			};
		case SET_EMAIL_CRM_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					CRMCategories: action.payload,
				},
			};
		case SET_EMAIL_EXTERNAL_CATEGORIES:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					externalCategories: action.payload,
				},
			};
		case SET_EMAIL_GRID_VIEW:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					gridView: action.payload,
				},
			};
		case SET_EMAIL_SEARCH_TEXT:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					searchText: action.payload,
				},
			};
		case SET_EMAIL_NEW_CATEGORY:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					newCategory: action.payload,
				},
			};
		case SET_EMAIL_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					categorySaveActions: action.payload,
				},
			};
		case SET_EMAIL_MAIN_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					mainCategorySaveActions: action.payload,
				},
			};
		case SET_EMAIL_DELETE_DATA:
			return {
				...state,
				categoryFilter: {
					...state.categoryFilter,
					deleteData: action.payload,
				},
			};
		case SET_EMAIL_FILTER:
			return { ...state, filter: action.payload };
		case RESET_ALL_EMAIL_FILTERS:
			return initialState;

		default:
			return state;
	}
};

export default EmailCreativesReducer;
