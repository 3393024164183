export const SELECT_SCRATCH_TEMPLATE = 'SELECT_SCRATCH_TEMPLATE';

export const DYNAMIC_FORMULA_CREATION = 'DYNAMIC_FORMULA_CREATION';

export const DYNAMIC_FORMULA_CURRENT_POSITION =
	'DYNAMIC_FORMULA_CURRENT_POSITION';

export const DYNAMIC_VARIABLE_QRCODE_PROPERTIES =
	'DYNAMIC_VARIABLE_QRCODE_PROPERTIES';

export const EMOJI_GROUPS = 'EMOJI_GROUPS';
export const EMOJIS = 'EMOJIS';

export const SHOW_SECTIONS = 'SHOW_SECTIONS';
export const SHOW_ROWS = 'SHOW_ROWS';
export const SHOW_CELLS = 'SHOW_CELLS';

export const OPEN_Blocks_SIDE_BAR = 'OPEN_Blocks_SIDE_BAR';
export const OPEN_STRUCTURE_SIDE_BAR = 'OPEN_STRUCTURE_SIDE_BAR';
export const OPEN_Leap360_SIDE_BAR = 'OPEN_Leap360_SIDE_BAR';
export const OPEN_ORG_SIDE_BAR = 'OPEN_ORG_SIDE_BAR';
export const OPEN_HEADER_SIDE_BAR = 'OPEN_HEADER_SIDE_BAR';
export const OPEN_FOOTER_SIDE_BAR = 'OPEN_FOOTER_SIDE_BAR';
export const DYNAMIC_VARIABLE_EDITOR_VAL = 'DYNAMIC_VARIABLE_EDITOR_VAL';
