import {
	WEBSITEBUILDERVIEW,
	WEBSITEBUILDERDATA,
	RESETWEBSITEBUILDERDATA,
} from './WebsiteBuilderSetupTypes';

interface IWebsiteBuilderSetup {
	websiteBuilderView: number;
	websiteInfo: {
		Website_Name: string;
		Email: string;
		Address: string;
		Phone: number;
		Features: string[];
		Selected_Theme_Id: string;
		Selected_Theme_Name: string;
		Selected_Theme_CSS: string;
		Selected_Layout: string;
	};
}

const initialState: IWebsiteBuilderSetup = {
	websiteBuilderView: 0,
	websiteInfo: {
		Website_Name: '',
		Email: '',
		Address: '',
		Phone: null,
		Features: [],
		Selected_Theme_Name: '',
		Selected_Theme_Id: '',
		Selected_Theme_CSS: '',
		Selected_Layout: '',
	},
};
const WebsiteBuilderSetup = (state: any = initialState, action) => {
	switch (action.type) {
		case WEBSITEBUILDERVIEW:
			return { ...state, websiteBuilderView: action.payload };
		case WEBSITEBUILDERDATA:
			return { ...state, websiteInfo: action.payload };
		case RESETWEBSITEBUILDERDATA:
			return { ...initialState };
		default:
			return state;
	}
};

export default WebsiteBuilderSetup;
