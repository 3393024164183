import { doc, updateDoc } from 'firebase/firestore';
import { GetUserProfilePic } from 'firebaseApis/user2FactorAuthentication';

import {
	createWithXProjKey,
	getClientDB,
	getOrganisationIdFirebase,
	siteConfigConstants,
} from './AxiosManager';

export const DeleteUserFromClientAuth = async (uid) => {
	try {
		const url = `${siteConfigConstants().REACT_APP_MISCELLANEOUS_URL}/deleteUser`;
		const instance = createWithXProjKey();

		const result = await instance.post(url, {
			Firebase_UID: uid,
		});
		return result;
	} catch (e) {
		return null;
	}
};

export const getCustomAccessToken = async (payload, authUrl = '') => {
	try {
		const url = authUrl || siteConfigConstants().REACT_APP_MISCELLANEOUS_URL;
		const instance = createWithXProjKey();
		const result = await instance.post(`${url}/getCustomUserToken`, payload);
		return result;
	} catch (e) {
		return null;
	}
};

export const saveGridState = async (payload: any) => {
	try {
		const user = await GetUserProfilePic();

		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				user.id
			),
			{ GridStates: payload }
		);
		return {
			success: true,
			message: 'Updated grid state successfully.',
		};
	} catch (error) {
		return error;
	}
};
