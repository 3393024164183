import React from 'react';
import Button from 'storybook-mui/build/components/Button';
import TwLogo from 'components/Logos/assets/001-twitter.svg';
import GgLogo from 'components/Logos/assets/008-search.svg';

import {
	GoogleSignIn,
	TwitterSignIn,
	GetOrganisationOfUser,
} from 'firebaseApis/authentication';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import { IUserDataInLocalStorage } from './onboardInterfaces';

function SocialLogins() {
	const dispatch = useDispatch();
	const history = useHistory();
	
	const redirectToCreateOrChooseOrgPage = async (userId) => {
		try {
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(userId);
			dispatch(hideLoading());
			if (resultData.length === 0) {
				history.push('/createorg');
			} else {
				history.push('/chooseorg');
			}
		} catch (err) {
			dispatch(hideLoading());
			history.push('/');
		}
	};
	const signInWithGoogle = async () => {
		try {
			const result = await GoogleSignIn();
			if (result.success) {
				dispatch(SetUserInfo(result.data));
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: result.data.Name,
					lName: '',
					email: result.data.Email,
					userId: result.data.User_Id,
					emailVerified: true,
					mobileType: '',
					countryCode: '',
					phoneNumber: '',
					landline_ext: '',
					email_optIn: false,
					sms_optIn: false,
					voice_optIn: false,
					userRegistrationType: 'google'
				};
				localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
				dispatch(SetAuthenticationType('LOGIN'));
				redirectToCreateOrChooseOrgPage(result.data.User_Id);
			} else {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const signInWithTwitter = async () => {
		try {
			const result = await TwitterSignIn();
			if (result.success) {
				dispatch(SetUserInfo(result.data));
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: result.data.Name,
					lName: result.data.Name,
					email: result.data.Email,
					userId: result.data.User_Id,
					emailVerified: true,
					mobileType: '',
					countryCode: '',
					phoneNumber: '',
					landline_ext: '',
					email_optIn: false,
					sms_optIn: false,
					voice_optIn: false,
					userRegistrationType: 'twitter'
				};
				localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
				dispatch(SetAuthenticationType('LOGIN'));
				redirectToCreateOrChooseOrgPage(result.data.User_Id);
			} else {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	return (
		<div className='social-login-btns-ct px-4'>
			<div className='w-full lg:justify-center lg:items-center flex flex-col lg:flex-row lg:my-5 gap-2 my-3 lg:gap-4'>
				<Button
					title='Continue with Google'
					onClick={signInWithGoogle}
					startIcon={<img src={GgLogo} width='30' height='30' />}
					color='inherit'
					variant='outlined'
					sx={{ color: '#28B446' }}
				/>
				<Button
					title='Continue with Twitter'
					onClick={() => signInWithTwitter()}
					startIcon={<img src={TwLogo} width='30' height='30' />}
					color='inherit'
					variant='outlined'
					sx={{ color: '#00A6DE' }}
				/>
			</div>
		</div>
	);
}

export default SocialLogins;
