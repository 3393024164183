import {
	SET_DYNAMIC_JSON_TYPE,
	SET_DYNAMIC_JSON,
	SET_DYNAMIC_JSON_VALUES,
	SET_DYNAMIC_JSON_MODULE_NAME,
	RELOAD_FIELD_OPTIONS_LIST,
	SET_VALIDATION_ON_CHANGE,
	SET_NEW_MODULE_DETAILS,
	SET_NEW_FIELD_DETAILS,
	SET_NAV_MENU,
	SET_DYNAMIC_MODULES,
	RESET_DYNAMIC_FORM,
	SET_GROUP_FIELDS,
	SET_SAMPLE_JSONS,
} from './DynamicActionTypes';

interface IDynamic {
	moduleName: string;
	jsonType: string;
	dynamicJSON: any;
	dynamicJSONValues: any;
	reloadOptions: boolean;
	validationOnChange: boolean;
	nabBarMenu: any;
	newModuleDetails: {
		dynamicformName: string;
		dynamicformDescription: string;
		runCampaigns: boolean;
		optInType: string;
		menu: any;
		subMenu: string;
		formPosition: 'after';
		runTwoWayCommunication: boolean;
	};

	newFieldDetails: {
		name: string;
		description: string;
		fieldType: string;
		storybookFieldType: string;
	};
	groupFields: any;
	dynamicModules: any;
	SampleJSONs: any;
}

const initialState: IDynamic = {
	moduleName: '',
	jsonType: 'live',
	dynamicJSON: {
		DynamicModuleAssociation: false,
		AllowTwoWayCommunication: false,
		DocumentName: '',
		Menu: null,
		FormPosition: 'after',
		SubMenu: null,
		ModuleName: '',
		ModuleDescription: '',
		DocumentsGrid: {
			DefaultColumns: [],
			GridDescription: '',
			GridTitle: '',
			HiddenColumns: [],
		},
		DynamicModuleAssociationFields: [],
		HaveUniqueCodeForDocument: false,
		PageDescriptionOnAdd: '',
		PageDescriptionOnEdit: '',
		PageLayout: 'Tabs',
		PageLogoOnAdd: '',
		PageLogoOnEdit: '',
		PageLogoPositionToTitle: 'left',
		PageTitleOnAdd: '',
		PageTitleOnEdit: '',
		IdenticalModulesToCopy: [],
		PrimaryKeyFields: [],
		DrawerConfig: {
			IconType: null,
			Title: '',
			Image: '',
			Width: 100,
		},
		DisableTabsClick: false,
		HaveResetButtonInTabs: false,
		TabLevelValidation: false,
		RunCampaigns: true,
		OptInType: 'NoOptIn',
		ShowPageLogo: false,
		Tabs: [
			{
				DeleteInAdmin: true,
				DisplayOrder: 1,
				EditableInAdmin: true,
				Enable: true,
				TabID: 1,
				TabName: 'Primary',
				Visible: true,
				ShowOnlyOnEdit: false,
				Sections: [],
			},
		],
	},
	dynamicJSONValues: null,
	reloadOptions: false,
	validationOnChange: false,
	nabBarMenu: [],
	dynamicModules: [],
	groupFields: [],
	SampleJSONs: [],
	newModuleDetails: {
		dynamicformName: '',
		dynamicformDescription: '',
		runCampaigns: true,
		optInType: 'NoOptIn',
		menu: null,
		subMenu: null,
		formPosition: 'after',
		runTwoWayCommunication: false,
	},
	newFieldDetails: {
		name: '',
		description: '',
		fieldType: 'single',
		storybookFieldType: null,
	},
};

const dynamicStore = (state: any = initialState, action: any): IDynamic => {
	switch (action.type) {
		case SET_DYNAMIC_JSON_TYPE:
			return { ...state, jsonType: action.payload };
		case SET_DYNAMIC_JSON:
			return { ...state, dynamicJSON: action.payload };
		case SET_DYNAMIC_JSON_VALUES:
			return { ...state, dynamicJSONValues: action.payload };
		case SET_DYNAMIC_JSON_MODULE_NAME:
			return { ...state, moduleName: action.payload };
		case RELOAD_FIELD_OPTIONS_LIST:
			return { ...state, reloadOptions: action.payload };
		case SET_VALIDATION_ON_CHANGE:
			return { ...state, validationOnChange: action.payload };
		case SET_NEW_MODULE_DETAILS:
			return { ...state, newModuleDetails: action.payload };
		case SET_NEW_FIELD_DETAILS:
			return { ...state, newFieldDetails: action.payload };
		case SET_NAV_MENU:
			return { ...state, nabBarMenu: action.payload };
		case SET_DYNAMIC_MODULES:
			return { ...state, dynamicModules: action.payload };
		case SET_GROUP_FIELDS:
			return { ...state, groupFields: action.payload };
		case SET_SAMPLE_JSONS:
			return { ...state, SampleJSONs: action.payload };
		case RESET_DYNAMIC_FORM:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default dynamicStore;
