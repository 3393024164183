export const SELECTED_WEBSITE = 'SELECTED_WEBSITE';
export const SELECTED_PAGE = 'SELECTED_PAGE';
export const SELECTED_PAGE_CONTENT = 'SELECTED_PAGE_CONTENT';

export const TOTAL_PAGES = 'TOTAL_PAGES';

export const BOOKING_NAV = 'BOOKING_NAV';
export const DEFAULT_HEADER = 'DEFAULT_HEADER';
export const DEFAULT_HEADER_ID = 'DEFAULT_HEADER_ID';
export const DEFAULT_FOOTER = 'DEFAULT_FOOTER';

export const SET_CATALOG_ID = 'SET_CATALOG_ID';
export const SET_CATALOG_LOCATION_ID = 'SET_CATALOG_LOCATION_ID';

export const ACTIVE_STICKY = 'ACTIVE_STICKY';

export const OPEN_STRUCTURE_SIDE_BAR = 'OPEN_WEBSITE_Blocks_SIDE_BAR';
export const OPEN_WEBSITE_Blocks_SIDE_BAR = 'OPEN_WEBSITE_Blocks_SIDE_BAR';
export const OPEN_WEBSITE_STRUCTURE_SIDE_BAR =
	'OPEN_WEBSITE_STRUCTURE_SIDE_BAR';
export const OPEN_WEBSITE_Leap360_SIDE_BAR = 'OPEN_WEBSITE_Leap360_SIDE_BAR';
export const OPEN_WEBSITE_ORG_SIDE_BAR = 'OPEN_WEBSITE_ORG_SIDE_BAR';
export const OPEN_WEBSITE_HEADER_SIDE_BAR = 'OPEN_WEBSITE_HEADER_SIDE_BAR';
export const OPEN_WEBSITE_FOOTER_SIDE_BAR = 'OPEN_WEBSITE_FOOTER_SIDE_BAR';
export const OPEN_WEBSITE_THEME_SIDE_BAR = 'OPEN_WEBSITE_THEME_SIDE_BAR';

export const SET_HEADER_CUSTOMIZE_DATA = 'SET_HEADER_CUSTOMIZE_DATA';
export const SET_HEADER_CUSTOMIZE_ID = 'SET_HEADER_CUSTOMIZE_ID';

export const SET_SELECTED_EMAIL_CREATIVE = 'SET_SELECTED_EMAIL_CREATIVE';
