import Slide from '@mui/material/Slide';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import CheckboxField from 'storybook-mui/build/components/Checkbox';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import {
	SetNewOrgData,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';

function FeatureSelection() {
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const allFeatures = [
		'Email',
		'SMS & MMS',
		'Social Media',
		'Voice',
		'Booking / Appointments',
		'Ecommerce',
		'Point of Sale',
		'Payment',
	];

	useEffect(() => {
		dispatch(increaseProgressVal(75));
	}, []);

	const onChangeCheckboxHandler = (feature) => {
		let newFeatures = newOrgStore.Org_Data.Features_Interested || [];
		if (newFeatures.includes(feature)) {
			newFeatures = newFeatures.filter((item) => item !== feature);
		} else {
			newFeatures.push(feature);
		}
		dispatch(
			SetNewOrgData({ ...newOrgStore.Org_Data, Features_Interested: newFeatures })
		);
	};

	const continueFeaturesHandler = () => {
		dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1));
	};

	return (
		<div className='w-full mx-auto feature-selection-ct'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='flex flex-col gap-6 lg:gap-10 justify-center items-center'>
					<div>
						<h2 className='text-2xl text-center lg:text-left lg:text-4xl font-bold text-primary-800 uppercase'>
							Which features are you interested in?
						</h2>
						<h4 className='text-lg :lgtext-xl text-gray-500 uppercase text-center'>
							You get it all, we’re just wondering.
						</h4>
					</div>
					<div className='lg:h-64 flex flex-wrap justify-center items-center bg-info-600 text-white w-full'>
						<div className='flex flex-wrap justify-center gap-2 checkbox-group-ct'>
							{allFeatures.map((feature) => (
								<CheckboxField
									key={feature}
									name='email'
									size='medium'
									checked={newOrgStore?.Org_Data?.Features_Interested?.includes(feature)}
									sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
									labelName={<h3 className='text-xl lg:text-2xl'>{feature}</h3>}
									onChange={() => {
										onChangeCheckboxHandler(feature);
									}}
									fieldId='email'
								/>
							))}
						</div>
					</div>
					<div>
						<p className='w-full lg:w-2/4 mx-auto text-base xl:text-xl text-gray-600'>
							Leap360 encompasses many useful features. Knowing your interests will
							help us improve your experience by prioritizing content relevent to your
							needs.
						</p>
					</div>
					<div className='bottom-section flex gap-6'>
						<Button
							variant='outlined'
							color='inherit'
							size='large'
							title={<h3 className='text-gray-500'>skip</h3>}
							onClick={() => continueFeaturesHandler()}
						/>
						<Button size='large' title='Continue' onClick={continueFeaturesHandler} />
					</div>
				</div>
			</Slide>
		</div>
	);
}

export default FeatureSelection;
