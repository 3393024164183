import {
	getClientDB,
	getOrganisationIdFirebase,
	getUser,
	getUserId,
} from 'api/AxiosManager';
import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { masterDb } from 'firebaseConfig';

export const GetDocumentInfo = async () => {
	try {
		const result = await getDocs(
			query(
				collection(masterDb, 'User2WayAuthentication'),
				where('userid', '==', getUserId())
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const isAuthenticationVerified = async (id: any, payload: any) => {
	try {
		await updateDoc(doc(masterDb, 'User2WayAuthentication', id), payload);
		return {
			success: true,
			message: '2-Factor Authentication set up successfully',
		};
	} catch (error) {
		return error;
	}
};

export const GetUserDocInfo = async () => {
	try {
		const result = await getDocs(
			query(collection(masterDb, 'Users'), where('Email', '==', getUser().email))
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
			password: d.data().Password,
			registrationType: d.data().userRegistrationType,
			...d.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const updatePasswordToFirebase = async (id: any, payload: any) => {
	try {
		await updateDoc(doc(masterDb, 'Users', id), payload);
		return {
			success: true,
			message: 'Password updated successfully',
		};
	} catch (error) {
		return error;
	}
};

export const updateImageToFirebase = async (id: any, payload: any) => {
	try {
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				id
			),
			{
				profilePic: payload,
			}
		);
		return {
			success: true,
			message: 'Image updated successfully',
		};
	} catch (error) {
		return error;
	}
};

export const GetUserProfilePic = async () => {
	try {
		const result = await getDocs(
			query(
				collection(
					getClientDB(),
					`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`
				),
				where('Associate_User', '==', getUserId())
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
			profilePic: d.data().profilePic,
			...d.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};
