import {
	SELECTED_PAGE,
	SELECTED_PAGE_CONTENT,
	SELECTED_WEBSITE,
	TOTAL_PAGES,
	BOOKING_NAV,
	DEFAULT_HEADER,
	DEFAULT_HEADER_ID,
	DEFAULT_FOOTER,
	ACTIVE_STICKY,
	OPEN_WEBSITE_STRUCTURE_SIDE_BAR,
	OPEN_WEBSITE_ORG_SIDE_BAR,
	OPEN_WEBSITE_HEADER_SIDE_BAR,
	OPEN_WEBSITE_FOOTER_SIDE_BAR,
	OPEN_WEBSITE_Blocks_SIDE_BAR,
	OPEN_WEBSITE_Leap360_SIDE_BAR,
	OPEN_WEBSITE_THEME_SIDE_BAR,
	SET_HEADER_CUSTOMIZE_DATA,
	SET_HEADER_CUSTOMIZE_ID,
	SET_CATALOG_ID,
	SET_CATALOG_LOCATION_ID,
	SET_SELECTED_EMAIL_CREATIVE,
} from './WebsiteBuilderActionTypes';

interface IBuilder {
	selectedPage: any;
	selectedWebsite: any;
	selectedPageContent: any;
	totalPages: [];
	bookingNavigation: {};
	defaultHeader: {};
	defaultHeaderId: string;
	catalogId: string;
	catalogLocationId: string;
	stickyType: string;
	selectedDefaultHeader: {};
	selectedDefaultFooter: {};
	selectedEmailCreative: {
		signup: '';
		welcomeaccount: '';
		orderconfirmation: '';
		ordercancellation: '';
	};

	webStructureSidebarActive: boolean;
	webLeap360SidebarActive: boolean;
	webOrgSidebarActive: boolean;
	webBlocksSidebarActive: boolean;
	webHeaderSidebarActive: boolean;
	webFooterSidebarActive: boolean;
	webThemeSidebarActive: boolean;

	headerCustomizeData: {}[];
	headerCustomizeId: string;
}

const initialState: IBuilder = {
	selectedPage: {},
	selectedPageContent: {},
	selectedWebsite: {},
	totalPages: [],
	bookingNavigation: {},
	defaultHeader: {},
	stickyType: '',
	selectedDefaultHeader: {},
	defaultHeaderId: '',
	catalogId: '',
	catalogLocationId: '',
	selectedDefaultFooter: {},
	selectedEmailCreative: {
		signup: '',
		welcomeaccount: '',
		orderconfirmation: '',
		ordercancellation: '',
	},

	webStructureSidebarActive: false,
	webLeap360SidebarActive: false,
	webOrgSidebarActive: false,
	webBlocksSidebarActive: true,
	webHeaderSidebarActive: false,
	webFooterSidebarActive: false,
	webThemeSidebarActive: false,

	headerCustomizeData: [],
	headerCustomizeId: '',
};

const WebsiteBuilder = (state: any = initialState, action): IBuilder => {
	switch (action.type) {
		case SELECTED_WEBSITE:
			return { ...state, selectedWebsite: action.payload };
		case SELECTED_PAGE:
			return { ...state, selectedPage: action.payload };

		case SELECTED_PAGE_CONTENT:
			return { ...state, selectedPageContent: action.payload };

		case TOTAL_PAGES:
			return { ...state, totalPages: action.payload };

		case BOOKING_NAV:
			return { ...state, bookingNavigation: action.payload };
		case DEFAULT_HEADER:
			return { ...state, selectedDefaultHeader: action.payload };

		case DEFAULT_HEADER_ID:
			return { ...state, defaultHeaderId: action.payload };

		case SET_CATALOG_ID:
			return { ...state, catalogId: action.payload };

		case SET_CATALOG_LOCATION_ID:
			return { ...state, catalogLocationId: action.payload };

		case DEFAULT_FOOTER:
			return { ...state, selectedDefaultFooter: action.payload };

		case ACTIVE_STICKY:
			return { ...state, stickyType: action.payload };

		case OPEN_WEBSITE_STRUCTURE_SIDE_BAR:
			return { ...state, webStructureSidebarActive: action.payload };
		case OPEN_WEBSITE_Leap360_SIDE_BAR:
			return { ...state, webLeap360SidebarActive: action.payload };
		case OPEN_WEBSITE_ORG_SIDE_BAR:
			return { ...state, webOrgSidebarActive: action.payload };
		case OPEN_WEBSITE_HEADER_SIDE_BAR:
			return { ...state, webHeaderSidebarActive: action.payload };
		case OPEN_WEBSITE_FOOTER_SIDE_BAR:
			return { ...state, webFooterSidebarActive: action.payload };
		case OPEN_WEBSITE_Blocks_SIDE_BAR:
			return { ...state, webBlocksSidebarActive: action.payload };

		case OPEN_WEBSITE_THEME_SIDE_BAR:
			return { ...state, webThemeSidebarActive: action.payload };

		case SET_HEADER_CUSTOMIZE_DATA:
			return { ...state, headerCustomizeData: action.payload };

		case SET_HEADER_CUSTOMIZE_ID:
			return { ...state, headerCustomizeId: action.payload };

		case SET_SELECTED_EMAIL_CREATIVE:
			return { ...state, selectedEmailCreative: action.payload };

		default:
			return state;
	}
};

export default WebsiteBuilder;
