import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
} from 'firebase/firestore';
import {
	getClientDB,
	getOrganisationIdFirebase,
	createCloud,
	siteConfigConstants,
} from 'api/AxiosManager';

export const GetOrganisationDetailsById = async (organisationId: any) => {
	try {
		// org info from client db
		const qry = query(
			collection(getClientDB(), `${organisationId}/Organization/Organization`)
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			docId: document.id,
		}));

		// converting new org info to match with existing structure in localStorage
		const orgInClientDB: any = resultArray[0];

		return orgInClientDB;
	} catch (error) {
		return error;
	}
};

export const SetOrganizationTreeData = async (payload: any) => {
	const { parentOrganizationId } = JSON.parse(localStorage.getItem('user'));
	try {
		// updating current organisation tree data
		await setDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/TreeData/OrganizationTreeData`,
				payload.id
			),
			payload
		);

		if (!parentOrganizationId) {
			// updating all sub organisations tree data
			payload.children.forEach(async (child: any) => {
				if (child.type === 'SUB-ORGANIZATION') {
					setDoc(
						doc(
							getClientDB(),
							`/${child.id}/TreeData/OrganizationTreeData`,
							child.id
						),
						child
					);
				}
			});
		} else {
			// updating parent tree data if current org is sub org

			const parentOrgTreeDataResult = await getDoc(
				doc(
					getClientDB(),
					`/${parentOrganizationId}/TreeData/OrganizationTreeData`,
					parentOrganizationId
				)
			);
			const parentOrgTreeData = parentOrgTreeDataResult.data();
			const updatedParentOrgChildren = parentOrgTreeData.children.map(
				(node: any) => (node.id === payload.id ? payload : node)
			);
			parentOrgTreeData.children = updatedParentOrgChildren;

			await setDoc(
				doc(
					getClientDB(),
					`/${parentOrganizationId}/TreeData/OrganizationTreeData`,
					parentOrganizationId
				),
				parentOrgTreeData
			);
		}

		return true;
	} catch (error) {
		return error;
	}
};

export const GetOrganizationTreeData = async () => {
	try {
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/TreeData/OrganizationTreeData`,
				getOrganisationIdFirebase()
			)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};

export const UpdateOrganizationInfoById = async (id: any, values: any) => {
	try {
		const result = await setDoc(
			doc(getClientDB(), `/${id}/Organization/Organization`, id),
			values
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const DeleteOrg = async () => {
	try {
		const payload: any = { organization_id: getOrganisationIdFirebase() };
		const instance = createCloud();
		const response: any = await instance.post(
			`${siteConfigConstants().REACT_APP_DELETE_ORGANIZATION}/delete`,
			payload
		);
		return response;
	} catch (e) {
		return e;
	}
};
