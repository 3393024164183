export const SET_NEW_PRODUCT_DATA = 'SET_NEW_PRODUCT_DATA';
export const SET_RESET_PRODUCT_DATA = 'SET_RESET_PRODUCT_DATA';
export const SET_ALL_PRODUCTS_DATA = 'SET_ALL_PRODUCTS_DATA';
export const OPEN_NEW_PRODUCT_MODEL = 'OPEN_NEW_PRODUCT_MODEL';
export const OPEN_ALL_IMAGE_MODEL = 'OPEN_ALL_IMAGE_MODEL';
export const OPEN_ALL_PRODUCT_MODEL = 'OPEN_ALL_PRODUCT_MODEL';
export const SET_PRODUCT_TAB_MODEL = 'SET_PRODUCT_TAB_MODEL';
export const SET_PRODUCT_ACTION_TYPE = 'SET_PRODUCT_ACTION_TYPE';
export const SET_LINKED_CATEGORY_LIST = 'SET_LINKED_CATEGORY_LIST';
export const SET_LINKED_IMAGES_LIST = 'SET_LINKED_IMAGES_LIST';

export interface SET_NEW_PRODUCT_DATA {
	type: typeof SET_NEW_PRODUCT_DATA;
	payload: any;
}

export interface SET_RESET_PRODUCT_DATA {
	type: typeof SET_RESET_PRODUCT_DATA;
}

export interface SET_ALL_PRODUCTS_DATA {
	type: typeof SET_ALL_PRODUCTS_DATA;
	payload: any;
}

export interface OPEN_NEW_PRODUCT_MODEL {
	type: typeof OPEN_NEW_PRODUCT_MODEL;
	payload: boolean;
}

export interface OPEN_ALL_PRODUCT_MODEL {
	type: typeof OPEN_ALL_PRODUCT_MODEL;
	payload: boolean;
}

export interface OPEN_ALL_IMAGE_MODEL {
	type: typeof OPEN_ALL_IMAGE_MODEL;
	payload: boolean;
}

export interface SET_PRODUCT_TAB_MODEL {
	type: typeof SET_PRODUCT_TAB_MODEL;
	payload: boolean;
}

export interface SET_PRODUCT_ACTION_TYPE {
	type: typeof SET_PRODUCT_ACTION_TYPE;
	payload: string;
}

export interface SET_LINKED_CATEGORY_LIST {
	type: typeof SET_LINKED_CATEGORY_LIST;
	payload: any;
}

export interface SET_LINKED_IMAGES_LIST {
	type: typeof SET_LINKED_IMAGES_LIST;
	payload: any;
}
export type ProductDispatchTypes =
	| SET_NEW_PRODUCT_DATA
	| SET_RESET_PRODUCT_DATA
	| SET_ALL_PRODUCTS_DATA
	| OPEN_NEW_PRODUCT_MODEL
	| OPEN_ALL_IMAGE_MODEL
	| OPEN_ALL_PRODUCT_MODEL
	| SET_PRODUCT_TAB_MODEL
	| SET_PRODUCT_ACTION_TYPE
	| SET_LINKED_CATEGORY_LIST
	| SET_LINKED_IMAGES_LIST
