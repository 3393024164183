export const SET_EMAIL_MODAL_TYPE = 'SET_EMAIL_MODAL_TYPE';
export const SET_EMAIL_ADD_CAT_DATA = 'SET_EMAIL_ADD_CAT_DATA';
export const SET_EMAIL_FILTER_MODAL = 'SET_EMAIL_FILTER_MODAL';
export const SET_EMAIL_PAGE_NUM = 'SET_EMAIL_PAGE_NUM';
export const SET_EMAIL_TOTAL_PAGES = 'SET_EMAIL_TOTAL_PAGES';
export const SET_EMAIL_TOTAL_ROWS = 'SET_EMAIL_TOTAL_ROWS';
export const SET_EMAIL_PAGE_SIZE = 'SET_EMAIL_PAGE_SIZE';
export const SET_EMAIL_MODAL_IMAGE = 'SET_EMAIL_MODAL_IMAGE';
export const SET_EMAIL_CRM_CATEGORIES = 'SET_EMAIL_CRM_CATEGORIES';
export const SET_EMAIL_EXTERNAL_CATEGORIES = 'SET_EMAIL_EXTERNAL_CATEGORIES';
export const SET_EMAIL_CATEGORY_SAVE_ACTIONS = 'SET_EMAIL_CATEGORY_SAVE_ACTIONS';
export const SET_EMAIL_MAIN_CATEGORY_SAVE_ACTIONS = 'SET_EMAIL_MAIN_CATEGORY_SAVE_ACTIONS';
export const SET_EMAIL_DELETE_DATA = 'SET_EMAIL_DELETE_DATA';
export const SET_EMAIL_GRID_VIEW = 'SET_EMAIL_GRID_VIEW';
export const SET_EMAIL_SEARCH_TEXT = 'SET_EMAIL_SEARCH_TEXT';
export const SET_EMAIL_OPEN_MODAL = 'SET_EMAIL_OPEN_MODAL';
export const SET_EMAIL_LOAD_TEMPLATE_BY_TYPE = 'SET_EMAIL_LOAD_TEMPLATE_BY_TYPE';
export const SET_EMAIL_SELECTED_EXTERNAL_CATEGORIES = 'SET_EMAIL_SELECTED_EXTERNAL_CATEGORIES';
export const SET_EMAIL_GRID_API_DATA = 'SET_EMAIL_GRID_API_DATA';
export const SET_EMAIL_GRID_LAST_DOCUMENT = 'SET_EMAIL_GRID_LAST_DOCUMENT';
export const SET_EMAIL_GRID_FIRST_DOCUMENT = 'SET_EMAIL_GRID_FIRST_DOCUMENT';
export const SET_EMAIL_INTERNAL_CATEGORIES = 'SET_EMAIL_INTERNAL_CATEGORIES';
export const SET_EMAIL_INTERNAL_SELECTED_CATEGORIES = 'SET_EMAIL_INTERNAL_SELECTED_CATEGORIES';
export const SET_EMAIL_SELECTED_CRM_CATEGORIES = 'SET_EMAIL_SELECTED_CRM_CATEGORIES';
export const SET_EMAIL_NEW_CATEGORY = 'SET_EMAIL_NEW_CATEGORY';
export const SET_EMAIL_EXIST_MAIN_SUB_CHECK= 'SET_EMAIL_EXIST_MAIN_SUB_CHECK';
export const SET_EMAIL_INTERNAL_SELECTED_MAIN_CATEGORIES = 'SET_EMAIL_INTERNAL_SELECTED_MAIN_CATEGORIES';
export const SET_EMAIL_FILTER = 'SET_EMAIL_FILTER';
export const RESET_ALL_EMAIL_FILTERS = 'RESET_ALL_EMAIL_FILTERS';
