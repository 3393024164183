import React, { useEffect } from 'react';
import 'layouts/components/welcome-layout.scss';
import Leap360Logo from 'assets/img/leap360.png';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { getSiteConfig } from 'firebaseApis/authentication';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Spinner } from 'components/Spinner';
import Loginform from './Loginform';

export const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const fetchData = async () => {
		const siteConfigLocalStorage = JSON.parse(localStorage.getItem('siteConfig'));
		if (siteConfigLocalStorage) {
			history.push('/Marketing/SingleChannelCampaigns/');
		} else if (window.location.hostname !== 'dev.leap360.com') {
			dispatch(showLoading());
			const result = await getSiteConfig(window.location.origin);
			const siteConfig = result.Constant_Value;
			window.location.replace(`${siteConfig.REACT_APP_LOGOUT_REDIRECT_URL}`);
		}
		dispatch(hideLoading());
	};
	useEffect(() => {
		fetchData();
	}, []);
	if (window.location.hostname !== 'dev.leap360.com') {
		return <Spinner />;
	}
	return (
		<div
			className='h-screen w-auto flex justify-center items-center px-4 lg:px-0'
			style={{ backgroundColor: '#D1D5DB' }}
		>
			<div className='shadow-custom bg-white p-4 rounded-md'>
				<div className='flex justify-center items-center w-1/2 lg:w-64 lg:h-64 lg:-mt-60 mx-auto'>
					<img src={Leap360Logo} className='w-full' />
				</div>
				<p className='text-center font-extrabold text-3xl  my-2  text-secondary-500'>
					WELCOME
				</p>
				<hr />
				<Loginform />
			</div>
		</div>
	);
};
export default Login;
