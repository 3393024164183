import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './style.scss';

export const TimeZoneFilter = ({
	fieldId,
	onChange,
	placeholder,
	errorMessage,
	valid = true,
	disabled,
	options,
	value,
	width = '100%',
	sx = { width },
	size = 'small',
	autoComplete = 'on',
	variant = 'outlined',
}: ITimeZone) => (
	<Autocomplete
		id={fieldId}
		sx={sx}
		options={options.sort((a, b) => -b.groupBy.localeCompare(a.groupBy)).reverse()}
		autoHighlight
		onChange={onChange}
		size={size}
		groupBy={(option: any) => option.groupBy}
		{...(value && { value })}
		disabled={disabled}
		renderInput={(params: any) => (
			<TextField
				{...params}
				error={!valid}
				size={size}
				helperText={!valid && errorMessage}
				label={placeholder}
				id={fieldId}
				variant={variant}
				inputProps={{
					...params.inputProps,
					autoComplete: '',
				}}
				autoComplete={autoComplete}
			/>
		)}
	/>
);

export interface ITimeZone {
	fieldId: string;
	onChange: any;
	errorMessage?: string;
	valid?: any;
	options: any[];
	disabled?: boolean;
	placeholder?: string;
	value?: any;
	optionsType?: 'plain' | 'render';
	sx?: any;
	width?: any;
	size?: 'small' | 'medium';
	autoComplete?: 'off' | 'on';
	variant?: 'outlined' | 'filled' | 'standard';
}

export default TimeZoneFilter;
