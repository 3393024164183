import React from 'react';
import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	useGridRootProps,
} from '@mui/x-data-grid-pro';
import Tooltip from 'storybook-mui/build/components/Tooltip';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import NoDataIcon from 'assets/img/no-data.svg';
import 'styles/MUIDataGrid.scss';

const GridCustomToolbar = () => {
	const rootProps = useGridRootProps();

	return (
		<GridToolbarContainer>
			<div className='flex gap-2 pr-3 items-center'>
				<GridToolbarColumnsButton
					onResize={() => {}}
					nonce=''
					onResizeCapture={() => {}}
				/>
				<GridToolbarFilterButton
					onResize={() => {}}
					nonce=''
					onResizeCapture={() => {}}
				/>
				<GridToolbarDensitySelector
					onResize={() => {}}
					nonce=''
					onResizeCapture={() => {}}
				/>
				{rootProps?.componentsProps?.toolbar?.onSavePreferences && (
					<Tooltip content='Save preferences' direction='bottom'>
						<Icon
							icon={IconTypes.Save}
							onClick={() => rootProps.componentsProps.toolbar.onSavePreferences()}
							className='cursor-pointer hover:text-gray-800'
						/>
					</Tooltip>
				)}
				{rootProps?.componentsProps?.toolbar?.onRevertPreferences && (
					<Tooltip content='Revert preferences' direction='bottom'>
						<Icon
							icon={IconTypes.Redo}
							onClick={() => rootProps.componentsProps.toolbar.onRevertPreferences()}
							className='cursor-pointer hover:text-gray-800'
						/>
					</Tooltip>
				)}
			</div>
		</GridToolbarContainer>
	);
};

export default function MUIGridGloabl({
	columns,
	dataSource,
	rowCount,
	initialState,
	onRowClick,
	rowSelection,
	onSelectionModelChange,
	selectionModal,
	setPagination = false,
	paginationMode = 'server',
	headerHeight = 45,
	gridHeight = '600px',
	Toolbar = true,
	noRecordsText = '',
	noRecordsCta,
	columnVisibilityModel,
	onColumnVisibilityModelChange,
	loading = false,
	sxStyle,
	hideFooter = false,
	isRowSelectable,
	apiRef,
	disableColumnFilter,
	onFilterModelChange,
	componentsProps,
	disableMultipleSelection = false,
	rowHeight = 55,
	filterMode = 'client',
}: IMUIGloabGrid) {
	return (
		<div style={{ height: gridHeight }} className='w-full bg-white shadow-md'>
			<DataGridPro
				sx={{ ...sxStyle, boxShadow: 2 }}
				hideFooter={hideFooter}
				headerHeight={headerHeight}
				rows={dataSource}
				columns={columns}
				rowCount={rowCount}
				pagination={setPagination}
				checkboxSelection={rowSelection}
				paginationMode={paginationMode}
				components={{
					Toolbar: Toolbar && GridCustomToolbar,

					NoRowsOverlay: () => (
						<div className='flex flex-col justify-center items-center gap-3 h-full w-full absolute mx-auto z-50'>
							<img src={NoDataIcon} className='w-14 h-14' />
							<span className='font-semibold text-xl text-gray-600 capitalize'>
								{noRecordsText}
							</span>
							{noRecordsCta}
						</div>
					),
					NoResultsOverlay: () => (
						<div className='flex flex-col justify-center items-center gap-3 h-full w-full absolute mx-auto z-50'>
							<img src={NoDataIcon} className='w-14 h-14' />
							<span className='font-semibold text-xl text-gray-600 capitalize'>
								{noRecordsText}
							</span>
							{noRecordsCta}
						</div>
					),
				}}
				onRowClick={onRowClick}
				loading={loading}
				initialState={initialState}
				onSelectionModelChange={onSelectionModelChange}
				selectionModel={selectionModal}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={onColumnVisibilityModelChange}
				isRowSelectable={isRowSelectable}
				apiRef={apiRef}
				disableColumnFilter={disableColumnFilter}
				{...(rowHeight && { getRowHeight: () => rowHeight })}
				onFilterModelChange={onFilterModelChange}
				componentsProps={componentsProps}
				disableMultipleSelection={disableMultipleSelection}
				filterMode={filterMode}
			/>
		</div>
	);
}

export interface IMUIGloabGrid {
	columns: any;
	dataSource: [];
	rowCount: number;
	initialState?: any;
	sxStyle?: any;
	hideFooter?: boolean;
	onRowClick?: any;
	rowSelection?: any;
	onSelectionModelChange?: any;
	selectionModal?: any;
	setPagination?: boolean;
	paginationMode?: 'server' | 'client';
	headerHeight?: any;
	gridHeight?: any;
	Toolbar?: boolean;
	onAddButtonTitleClick?: any;
	addButtonTitle?: string;
	noRecordsText?: string;
	noRecordsCta?: any;
	onColumnVisibilityModelChange?: any;
	columnVisibilityModel?: any;
	loading?: boolean;
	isRowSelectable?: any;
	apiRef?: any;
	disableColumnFilter?: boolean;
	rowHeight?: any;
	onFilterModelChange?: any;
	componentsProps?: any;
	disableMultipleSelection?: boolean;
	filterMode?: 'server' | 'client';
}
