import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

// Toast
import { ToastContainer } from 'react-toastify';

// Redux
import { Provider } from 'react-redux';
import store from 'reducers';

// Users and Groups

// i18n
import { I18nContextProvider } from './i18n';

// Styles
import './styles/index.scss';
import './styles/MUIComponents.scss';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import Main from './main';

LicenseInfo.setLicenseKey(
	'249b04169d630d47e87bbf0c9eaa51cfT1JERVI6Mzk0MDEsRVhQSVJZPTE2Nzg1MTI0NTQwMDAsS0VZVkVSU0lPTj0x'
);

const theme = createTheme({
	typography: {
		fontFamily: `"Noto Sans","Roboto", "Helvetica", "Arial", sans-serif`,
	},
	palette: {
		primary: {
			main: '#DF8B0C',
		},
		info: {
			main: '#004C93',
		},
		secondary: {
			main: '#47484C',
		},
		warning: {
			main: '#713D00',
		},
		error: {
			main: '#D08360',
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<I18nContextProvider>
			<Provider store={store as any}>
				<ThemeProvider theme={theme}>
					<Main />
				</ThemeProvider>
			</Provider>
		</I18nContextProvider>
		<ToastContainer />
	</React.StrictMode>,
	document.getElementById('root')
);
