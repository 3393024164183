import {
	RESET_SMS_CREATIVE,
	SET_SMS_DYNAMIC_VARIABLES,
	SET_SMS_MAIN_MESSAGE,
	SET_SMS_VIEW,
	SET_SMS_REPLY,
	SET_SMS_RESPONSE,
	ADD_SMS_REPLY,
	SET_SMS_TREE_DATA,
	SET_SMS_SELECTED_CATEGORY,
	SET_SMS_SELECTED_SUBCATEGORY,
	SET_SMS_CATEGORIES,
	SET_SMS_CREATIVE_NAME,
	SET_SMS_NODE_ID,
	SET_SMS_DISPLAY_MESSAGE,
	SET_SMS_MEDIA_URLS,
	SET_SMS_MAIN_MEDIA_URLS,
	SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC,
	RESET_ALL_SMS_FILTERS,
	SET_SMS_SEARCH_TEXT,
	SET_SMS_FILTER,
	SET_SMS_GRID_VIEW,
	SET_SMS_CREATIVE,
	SET_SMS_SHOW_DELETE_MODAL,
	SET_SMS_CREATIVES,
	SET_SMS_LAST_VISIBLE,
	SET_SMS_FIRST_VISIBLE,
	SET_SMS_PAGE_NUM,
	SET_SMS_PAGE_SIZE,
	SET_SMS_TOTAL_ROWS,
	SET_SMS_TOTAL_PAGES,
	SET_SMS_FILTER_MODAL,
	SET_SMS_MODAL_IMAGE,
	SET_SMS_INTERNAL_CATEGORIES,
	SET_SMS_CATEGORY_SAVE_ACTIONS,
	SET_SMS_MAIN_CATEGORY_SAVE_ACTIONS,
	SET_SMS_INTERNAL_SELECTED_CATEGORIES,
	SET_SMS_ADD_CAT_DATA,
	SET_SMS_DELETE_DATA,
	SET_SMS_OPEN_MODAL,
	SET_SMS_MODAL_TYPE,
	SET_SMS_NEW_CATEGORY,
} from './SMSCreativesActionTypes';

interface ISMSCreative {
	mainMessage: string;
	mainMessageHaveDynamicVariable: boolean;
	view: number;
	dynamicVariables: [];
	smsReply: string;
	smsResponse: string;
	smsReplies: [];
	smsTreeData: any;
	selectedCategory: string;
	selectedSubCategory: string;
	categories: [];
	creativeName: string;
	id: string;
	currentId: string;
	displayMessage: string;
	smsMediaUrls: [];
	smsMainMediaUrls: [];
	smsFilters: {};
}

const initialState: ISMSCreative = {
	mainMessage: '',
	mainMessageHaveDynamicVariable: false,
	displayMessage: '',
	view: 1,
	dynamicVariables: [],
	smsReply: '',
	smsResponse: '',
	smsReplies: [],
	smsTreeData: { children: [] },
	selectedCategory: '',
	selectedSubCategory: '',
	categories: [],
	creativeName: '',
	id: '',
	currentId: '',
	smsMediaUrls: [],
	smsMainMediaUrls: [],
	smsFilters: {
		searchText: '',
		filter: false,
		gridView: false,
		creative: {},
		showDeleteModal: false,
		smsCreatives: [],
		lastVisible: {},
		firstVisible: {},
		pageNum: 0,
		pageSize: 10,
		totalRows: 10,
		totalPages: 0,
		filterModal: {},
		modalImage: '',
		internalCategories: '',
		categorySaveActions: {},
		mainCategorySaveActions: {},
		selectedInternalCategories: [],
		addCatData: {},
		deleteData: {},
		openModal: false,
		modalType: '',
		newCategory: '',
	},
};
const CreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_SMS_MAIN_MESSAGE:
			return { ...state, mainMessage: action.payload };

		case SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC:
			return { ...state, mainMessageHaveDynamicVariable: action.payload };

		case SET_SMS_DISPLAY_MESSAGE:
			return { ...state, displayMessage: action.payload };
		case SET_SMS_VIEW:
			return { ...state, view: action.payload };
		case SET_SMS_DYNAMIC_VARIABLES:
			return { ...state, dynamicVariables: action.payload };
		case SET_SMS_REPLY:
			return { ...state, smsReply: action.payload };
		case SET_SMS_RESPONSE:
			return { ...state, smsResponse: action.payload };
		case ADD_SMS_REPLY:
			return { ...state, smsReplies: action.payload };
		case SET_SMS_TREE_DATA:
			return { ...state, smsTreeData: action.payload };
		case SET_SMS_MEDIA_URLS:
			return { ...state, smsMediaUrls: action.payload };
		case SET_SMS_MAIN_MEDIA_URLS:
			return { ...state, smsMainMediaUrls: action.payload };
		case SET_SMS_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		case SET_SMS_SELECTED_SUBCATEGORY:
			return { ...state, selectedSubCategory: action.payload };

		case SET_SMS_CATEGORIES:
			return { ...state, categories: action.payload };
		case SET_SMS_CREATIVE_NAME:
			return { ...state, creativeName: action.payload };
		case SET_SMS_NODE_ID:
			return { ...state, currentId: action.payload };
		case SET_SMS_SEARCH_TEXT:
			return {
				...state,
				smsFilters: { ...state.smsFilters, searchText: action.payload },
			};
		case SET_SMS_FILTER:
			return {
				...state,
				smsFilters: { ...state.smsFilters, filter: action.payload },
			};
		case SET_SMS_GRID_VIEW:
			return {
				...state,
				smsFilters: { ...state.smsFilters, gridView: action.payload },
			};
		case SET_SMS_CREATIVE:
			return {
				...state,
				smsFilters: { ...state.smsFilters, creative: action.payload },
			};
		case SET_SMS_SHOW_DELETE_MODAL:
			return {
				...state,
				smsFilters: { ...state.smsFilters, showDeleteModal: action.payload },
			};
		case SET_SMS_CREATIVES:
			return {
				...state,
				smsFilters: { ...state.smsFilters, smsCreatives: action.payload },
			};
		case SET_SMS_LAST_VISIBLE:
			return {
				...state,
				smsFilters: { ...state.smsFilters, lastVisible: action.payload },
			};
		case SET_SMS_FIRST_VISIBLE:
			return {
				...state,
				smsFilters: { ...state.smsFilters, firstVisible: action.payload },
			};
		case SET_SMS_PAGE_NUM:
			return {
				...state,
				smsFilters: { ...state.smsFilters, pageNum: action.payload },
			};
		case SET_SMS_PAGE_SIZE:
			return {
				...state,
				smsFilters: { ...state.smsFilters, pageSize: action.payload },
			};
		case SET_SMS_TOTAL_ROWS:
			return {
				...state,
				smsFilters: { ...state.smsFilters, totalRows: action.payload },
			};
		case SET_SMS_TOTAL_PAGES:
			return {
				...state,
				smsFilters: { ...state.smsFilters, totalPages: action.payload },
			};
		case SET_SMS_FILTER_MODAL:
			return {
				...state,
				smsFilters: { ...state.smsFilters, filterModal: action.payload },
			};
		case SET_SMS_MODAL_IMAGE:
			return {
				...state,
				smsFilters: { ...state.smsFilters, modalImage: action.payload },
			};
		case SET_SMS_INTERNAL_CATEGORIES:
			return {
				...state,
				smsFilters: { ...state.smsFilters, internalCategories: action.payload },
			};
		case SET_SMS_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				smsFilters: { ...state.smsFilters, categorySaveActions: action.payload },
			};
		case SET_SMS_MAIN_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					mainCategorySaveActions: action.payload,
				},
			};
		case SET_SMS_INTERNAL_SELECTED_CATEGORIES:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					selectedInternalCategories: action.payload,
				},
			};
		case SET_SMS_ADD_CAT_DATA:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					addCatData: action.payload,
				},
			};
		case SET_SMS_DELETE_DATA:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					deleteData: action.payload,
				},
			};
		case SET_SMS_OPEN_MODAL:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					openModal: action.payload,
				},
			};
		case SET_SMS_MODAL_TYPE:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					modalType: action.payload,
				},
			};
		case SET_SMS_NEW_CATEGORY:
			return {
				...state,
				smsFilters: {
					...state.smsFilters,
					newCategory: action.payload,
				},
			};
		case RESET_ALL_SMS_FILTERS:
			return { ...state, smsFilters: initialState.smsFilters };
		case RESET_SMS_CREATIVE:
			return {
				mainMessage: '',
				displayMessage: '',
				mainMessageHaveDynamicVariable: false,
				view: 1,
				dynamicVariables: [],
				smsReply: '',
				smsResponse: '',
				smsReplies: [],
				smsTreeData: { children: [] },
				selectedCategory: '',
				categories: [],
				creativeName: '',
				id: '',
				currentId: '',
				smsMediaUrls: [],
				smsMainMediaUrls: [],
				smsFilters: state.smsFilters
			};
		default:
			return state;
	}
};

export default CreativesReducer;
