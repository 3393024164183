import {
	create,
	getOrganisationIdFirebase,
	siteConfigConstants,
} from 'api/AxiosManager';

export const GetPosPaymentTerminals = async () => {
	try {
		const instance = create();
		const result = await instance.get(
			`${
				siteConfigConstants().REACT_APP_POS_STORE
			}/payment_terminals/list?Organization_Id=${getOrganisationIdFirebase()}&env=${
				process.env.REACT_APP_PAYMENT_ENV
			}`
		);
		return result.data;
	} catch (error) {
		return [];
	}
};

export const RefreshPosPaymentTerminals = async () => {
	try {
		const instance = create();
		const result = await instance.get(
			`${
				siteConfigConstants().REACT_APP_POS_STORE
			}/payment_terminals/refresh?Organization_Id=${getOrganisationIdFirebase()}&env=${
				process.env.REACT_APP_PAYMENT_ENV
			}`
		);
		return result.data;
	} catch (error) {
		return error;
	}
};
